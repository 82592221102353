import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import TextField from '@mui/material/TextField'
import axios from 'axios'

type Props = {
  csrfToken: string,
  recaptchaKey: string,
}

const TimesheetPasscodeDialog: FunctionComponent<Props> = ({ csrfToken, recaptchaKey }: Props): JSX.Element => {
  const [ open, setOpen ] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const recaptcha_action = 'authenticate_timesheets'

  const handleClickOpen = () => {
    setOpen(!window.location.href.includes('timesheets/new'))
  }

  const handleClose = () => {
    setOpen(false)
    setError(null)
    setSuccess(null)
  }

  const grecaptchaObject = window.grecaptcha
  const submitFormWithRecaptcha = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    setError(null)
    setSuccess(null)

    grecaptchaObject.enterprise.ready(() => {
      grecaptchaObject.enterprise
      .execute(recaptchaKey, { action: recaptcha_action })
      .then((recaptchaToken: string) => {
          submitForm(recaptchaToken, event)
        })
    })
  }

  useEffect(() => {
    if(success !== null) {
      console.log('success', success)
      // redirect user to timesheets/new
      window.location.href = '/timesheets/new?passcode=' + success
    }
    return () => setSuccess(null)
  }, [success])

  const submitForm = (recaptchaToken: string, event: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.target as HTMLFormElement)
    const formJson = Object.fromEntries((formData as any).entries())
    const passcode = formJson.passcode
    console.log(passcode)

    const url = '/timesheets/authenticate'
    const payload = {
      passcode,
      recaptcha_token: recaptchaToken,
      recaptcha_action,
    }
    const config = {
      headers: {
        'content-type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
    }

    axios.post(url, payload, config)
      .then((response) => {
        setLoading(false)
        setSuccess(response.data.passcode)
        // reset()
      })
      .catch((error) => {
        console.log({error})
        setLoading(false)
        setSuccess(null)
        setError(error.response.data.error)
      })
  }

  return (
    <Fragment>
      <label role="button" onClick={handleClickOpen}>
        <i className="fa fa-user-clock me-1" />
        <small>Timesheets</small>
      </label>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          component: 'form',
          onSubmit: submitFormWithRecaptcha
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Please enter your passcode
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Submitting timesheets requires a passcode.
            Your passcode was provided to you when you signed up.
          </DialogContentText>
          <br />
          <TextField
            autoFocus
            required
            // margin="dense"
            id="passcode"
            name="passcode"
            label="Passcode"
            type="password"
            fullWidth
            variant="outlined"
            error={error ? true : false}
            helperText={error ? "Incorrect entry" : null}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="outlined"
            className="float-end"
            size="large"
            type="submit"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default TimesheetPasscodeDialog