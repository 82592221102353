import React, { FunctionComponent, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'

type Props = {
  csrfToken: string,
  recaptchaKey: string,
}

const ContactUsForm: FunctionComponent<Props> = ({ csrfToken, recaptchaKey }: Props): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const recaptcha_action = 'submit_contact_us'

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setError(null)
        setSuccess(null)
      }, 5000)
      return () => clearTimeout(timer)
    }
    return
  }, [success, error])

  const grecaptchaObject = window.grecaptcha

  const submitFormWithRecaptcha = (data: any) => {
    setLoading(true)
    setError(null)
    setSuccess(null)

    grecaptchaObject.enterprise.ready(() => {
      grecaptchaObject.enterprise.execute(recaptchaKey, { action: recaptcha_action }).then((recaptchaToken: string) => {
        submitForm(recaptchaToken, data)
      })
    })
  }

  const submitForm = async (recaptchaToken: string, data: any) => {
    const url = '/email'
    const payload = {
      email: data,
      recaptcha_token: recaptchaToken,
      recaptcha_action,
    }
    const config = {
      headers: {
        'content-type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
    }

    axios.post(url, payload, config)
      .then((response) => {
        setLoading(false)
        setSuccess(response.data)
        reset()
      })
      .catch((error) => {
        setLoading(false)
        setSuccess(null)
        setError(error.response.data)
      })
  }

  const submitButton = (): JSX.Element => {
    if (loading)
      return <button type="submit" className="btn btn-primary btn-lg text-light px-5" disabled>Sending</button>

    return(
      <>
        <LoadingButton
          loading={loading}
          loadingPosition="end"
          endIcon={<SendIcon />}
          variant="outlined"
          className="float-end"
          type="submit"
          size="large"
        >
          Send
        </LoadingButton>
        {error && <div className="alert alert-danger my-3" role="alert">{error}</div>}
        {success && <div className="alert alert-success my-3" role="alert">{success}</div>}
      </>
    )
  }

  return (
    <form id="contact-us" onSubmit={handleSubmit(submitFormWithRecaptcha)} method="POST" noValidate>
      <div className="pb-3 input-group">
        <input
          type="text"
          className={`form-control ${errors.name && "is-invalid"}`}
          placeholder="Name"
          required
          {...register('name', {
            required: { value: true, message: 'Please enter your name' },
            maxLength: {
              value: 30,
              message: 'Please use 30 characters or less'
            }
          })}
        />
        {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
      </div>
      <div className="pb-3 input-group">
        <input
          type="email"
          className={`form-control ${errors.address_from && "is-invalid"}`}
          placeholder="Email"
          required
          {...register('address_from', {
            required: true,
            pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          })}
        />
        {errors.address_from && (
          <div className="invalid-feedback">Please enter a valid email address</div>
        )}
      </div>
      <div className="pb-3 input-group">
        <textarea
          className={`form-control ${errors.message && "is-invalid"}`}
          placeholder="Message"
          required
          rows={10}
          {...register('message', {
            required: true
          })}
        />
        {errors.message && <div className="invalid-feedback">Please enter a message</div>}
      </div>
      <div className="col-12">
        {submitButton()}
      </div>
    </form>
  )
}

export default ContactUsForm
