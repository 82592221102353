import React, { FunctionComponent, CSSProperties, useState, useEffect, FormEvent } from 'react'
import axios from 'axios'

type Props = {
  csrfToken: string,
  recaptchaKey: string,
}

declare global {
  interface Window {
    grecaptcha: any
  }
}

const TimesheetUploader: FunctionComponent<Props> = ({ csrfToken, recaptchaKey }: Props): JSX.Element => {
  const [file, setFile] = useState<File | null>(null)
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [errorUploading, setErrorUploading] = useState(false)

  const grecaptchaObject = window.grecaptcha
  const recaptcha_action = 'upload_timesheet'

  const fileSelected = (e: FormEvent<HTMLInputElement>): void => {
    const files = (e.target as HTMLInputElement).files || []
    if (files.length > 0) setFile(files[0])
  }

  const uploadFileWithRecaptcha = (): void => {
    grecaptchaObject.enterprise.ready(() => {
      grecaptchaObject.enterprise.execute(recaptchaKey, {action: recaptcha_action}).then((recaptchaToken: string) => {
        uploadFile(recaptchaToken)
      })
    })
  }

  const uploadFile = async (recaptchaToken: string): Promise<void> => {
    if (file) {
      setUploading(true)
      const url = '/timesheets'
      const formData: any = new FormData()
      formData.append('file', file)
      formData.append('recaptcha_token', recaptchaToken)
      formData.append('recaptcha_action', recaptcha_action)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
      }

      axios.post(url, formData, config)
        .then((response) => {
          setUploading(false)
          setUploaded(true)
        })
        .catch((error) => {
          console.error('Error uploading timesheet', error)
          setUploading(false)
          setErrorUploading(true)
        })
    }
  }

  useEffect(() => {
    if (uploaded || errorUploading) {
      const timer = setTimeout(() => {
        resetFile()
      }, 5000)
      return () => clearTimeout(timer)
    }
    return
  }, [uploaded, errorUploading])

  const resetFile = () => {
    setFile(null)
    setErrorUploading(false)
    setUploaded(false)
    setUploading(false)
  }

  const content = () => {
    if (!file)
      return (
        <label role="button">
          <i className="fa fa-user-clock me-1" />
          <input className="d-none timesheets-file-input" type="file" onChange={ (e) => fileSelected(e) } />
          <small>Timesheets</small>
        </label>
      )

    if (uploading)
      return(
        <small>
          <span className="d-inline-block text-truncate" style={ { maxWidth: "150px" } as CSSProperties}>
            <i className="fa fa-file me-1"/>
            {file.name}
          </span>
          <br />
          <span className="btn-group btn-group-sm" role="group" style={ { width: "150px" } as CSSProperties}>
            <button className="btn btn-primary text-light" type="button">
              <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
              Uploading...
            </button>
          </span>
        </small>
      )

    if (uploaded)
      return(
        <small>
          <span className="d-inline-block text-truncate" style={ { maxWidth: "150px" } as CSSProperties}>
            <i className="fa fa-file me-1"/>
            {file.name}
          </span>
          <br />
          <span className="btn-group btn-group-sm bg-dark" role="group">
            <button type="button" className="btn btn-outline-success" onClick={resetFile}>
              <i className="fa fa-check-circle me-1" />
              Upload complete.
            </button>
          </span>
        </small>
      )

    if (errorUploading)
      return(
        <small>
          <span className="d-inline-block text-truncate" style={ { maxWidth: "150px" } as CSSProperties}>
            <i className="fa fa-file me-1"/>
            {file.name}
          </span>
          <br />
          <span className="btn-group btn-group-sm bg-dark" role="group">
            <button type="button" className="btn btn-outline-danger" onClick={resetFile}>
              <i className="fa fa-times-circle me-1" />
                Failed to upload.
                <br />
                Please try again.
            </button>
          </span>
        </small>
      )

    return (
      <small>
        <span className="d-inline-block text-truncate" style={ { maxWidth: "150px" } as CSSProperties}>
          <i className="fa fa-file me-1"/>
          {file.name}
        </span>
        <br />
        <span className="btn-group btn-group-sm bg-dark" role="group">
          <button type="button" className="btn btn-outline-primary" onClick={uploadFileWithRecaptcha}>
          <i className="fa fa-arrow-circle-up me-1" />
            Upload
          </button>
          <button type="button" className="btn btn-outline-secondary" onClick={resetFile}>
            <i className="fa fa-times-circle me-1" />
            Cancel
          </button>
        </span>
      </small>
    )
  }

  return (
    <>{content()}</>
  )
}

export default TimesheetUploader
