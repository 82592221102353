import React, { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

const TimesheetInstructions: FunctionComponent = (): JSX.Element => {
  return (
    <Box component='section' sx={{ p: 2, mb: 4, border: '1px solid rgba(0,0,0,0.25)', borderRadius: '4px' }}>
      <Typography variant='subtitle1' component='div'>
        <h5>Instructions:</h5>

        <p>Timesheets must be submitted as soon as you finish work for the week, at the latest by 6pm Sunday night for the previous week worked (Monday to Sunday).</p>
        <p>Use the notes section for: allowances, locations (if different certain days), travel/flight details, standdown or service numbers.</p>
        <p>If you have service docket's or receipt etc. please email them to timesheets@surepeople.net.au  with the details of what they are for.</p>
        <p>Please make sure you double check all details are correct (including AM or PM shift) before submitting, and downloading a copy for your records (please wait a few seconds after submitting as this lets you know it has been submitted successfully).</p>
      </Typography>
    </Box>
  )
}

export default TimesheetInstructions