import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import dayjs, { Dayjs } from 'dayjs'
import TextField from '@mui/material/TextField'
import { styled, useTheme } from '@mui/material/styles'
import { DateTimeValidationError } from '@mui/x-date-pickers/models'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { DailyData, GrandTotal } from './TimesheetForm'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

const columns = 24
const ItemWidths = {
  date:   { lg: 4, md: 6, sm: 18, xs: 16 },
  start:  { lg: 3, md: 4, xs: 6 },
  end:    { lg: 3, md: 4, xs: 6 },
  breaks: { lg: 3, md: 4, xs: 5 },
  total:  { lg: 3, md: 4, xs: 5 },
  notes:  { lg: 7, md: 22, xs: 24 },
  reset:  { lg: 1, md: 2, sm: 2, xs: 4 }
}
const Item = ({children}: any) => (<>{children}</>)

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 1 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 0 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>

const calculateDuration = (start: Dayjs | null, end: Dayjs | null) => {
  if (!start || !end) {
    return dayjs({ hour: 0, minute: 0 })
  }

  if(end.isBefore(start)) {
    // handle night shift
    const d1 = dayjs.duration(dayjs({ hour: 24 }).diff(start))
    const d2 = dayjs.duration(end.diff(dayjs({ hour: 0 })))
    return dayjs({ hour: d1.hours() + d2.hours(), minute: d1.minutes() + d2.minutes() })
  }

  const duration = dayjs.duration(end.diff(start))

  return dayjs({ hour: duration.hours(), minute: duration.minutes() })
}

const calculateTotal = (data: DailyData) => {
  data.total = calculateDuration(data.start, data.end)

  if (data.breaks && !data.breaks.isAfter(data.total)) {
    data.total = calculateDuration(data.breaks, data.total)
  }

  return data
}

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, 'week');
}

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null;
    hoveredDay?: Dayjs | null;
  },
) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

type TimePickerProps = React.ComponentProps<typeof TimePicker> & any
const SpsTimePicker = (props: TimePickerProps) => {
  const {helperText, ...rest} = props
  const [error, setError] = React.useState<DateTimeValidationError | null>(null);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      default: return ''
    }
  }, [error]);

  return (
    <TimePicker
      {...rest}
      onError={(newError) => setError(newError)}
      ampm={true}
      sx={{
        width: '100%',
        '& .MuiInputAdornment-positionStart': { marginRight: 0 }
      }}
      slotProps={{
        inputAdornment: {
          position: 'start',
        },
        textField: {
          helperText: errorMessage || helperText,
        },
      }}
    />
  )
}

type TimeFieldProps = React.ComponentProps<typeof TimeField> & any
const Breaks = (props: TimeFieldProps) => {
  const [error, setError] = React.useState<DateTimeValidationError | null>(null);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'maxTime': return 'Must be less than total duration'
      default: return ''
    }
  }, [error])

  return (
    <TimeField
      {...props}
      defaultValue={dayjs({ hour:0, minute:0 })}
      onError={(newError) => setError(newError)}
      sx={{ width: '100%' }}
      slotProps={{
        textField: {
          helperText: errorMessage || ' ',
        },
      }}
    />
  )
}

type DatePickerProps = React.ComponentProps<typeof DatePicker> & any
const SpsDatePicker = (props: DatePickerProps) => {
  const { helperText, ...rest } = props
  const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
  const [open,setOpen] = useState(false)

  const theme = useTheme()
  const lgScreenSize = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <DatePicker
      {...rest}
      open={open}
      onClose={() => setOpen(false)}
      showDaysOutsideCurrentMonth
      format={lgScreenSize ? 'dddd D MMM, YYYY' : 'ddd D MMM, YYYY'}
      slots={{ day: Day }}
      sx={{
        width: '100%',
        '& .MuiInputAdornment-positionStart': { marginRight: 0 },
        '& .MuiButtonBase-root': { padding: '8px 0 8px 8px' }
      }}
      slotProps={{
        openPickerButton: {
          color: 'primary',
        },
        inputAdornment: {
          position: 'start',
        },
        field: {
          readOnly: true,
          onClick: () => setOpen(!props.readOnly),
          helperText: helperText,
        },
        day: (ownerState) =>
          ({
            selectedDay: props.value,
            hoveredDay,
            onPointerEnter: () => setHoveredDay(ownerState.day as Dayjs),
            onPointerLeave: () => setHoveredDay(null),
          } as any),
      }}
    />
  )
}

const Total = (props: TimeFieldProps) => (
  <TimeField
    {...props}
    sx={{ width: '100%' }}
    slotProps={{
      textField: {
        size: 'medium',
        helperText: ' ',
      }
    }}
  />
)

const GrandTotalField = (props: TimeFieldProps) => {
  const theme = useTheme()
  const lgScreenSize = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <TextField
      {...props}
      label={lgScreenSize ? 'Total' : 'hrs:mins'}
      helperText=' '
    />
  )
}

type TextFieldProps = React.ComponentProps<typeof TextField>
const Notes = (props: any) => {
  const { onChange, ...rest } = props
  const [error, setError] = React.useState<boolean>(false)
  const onValueChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.value.length >= 200) {
      setError(true)
      return
    }

    setError(false)
    onChange({ target })
  }

  return (
    <TextField
      {...rest}
      onChange={onValueChange}
      error={error}
      helperText={(error && "Max length reached") || ' '}
      variant="outlined"
      multiline
      maxRows={4}
      fullWidth
      inputProps={{ maxLength: 200 }}
    />
  )
}

type TimesheetRowProps = {
  index: number,
  day: string,
  date: Dayjs,
  data: DailyData[],
  setData: React.Dispatch<React.SetStateAction<DailyData[]>>
}
const TimesheetRow = ({ index, date, data, setData }: TimesheetRowProps) => {
  const setSelectedDate = (newValue: Dayjs) => {
    setData((prev) => {
      const newData = [...prev]
      newData.forEach((el) => el.date = newValue.weekday(el.id))
      return newData
    })
  }

  const resetRow = () => {
    data[index].start = null
    data[index].end = null
    setData((prev) => {
      const newData = [...prev]
      newData[index].start = null
      newData[index].end = null
      newData[index].breaks = null
      newData[index].notes = null
      newData[index].total = dayjs({ hour: 0, minute: 0 })
      return newData
    })
  }

  const displayResetIcon = () => {
    return data[index].start || data[index].end || data[index].breaks ? 'block' : 'none'
  }

  const ResetButton = () => {
    return (
      <Button size="small" onClick={resetRow} sx={{ display: displayResetIcon }}>
        <DeleteIcon />
        Clear
      </Button>
    )
  }

  const theme = useTheme()
  const smScreenSize = useMediaQuery(theme.breakpoints.up('md'))
  const lgScreenSize = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <>
      <Grid item {...ItemWidths.date} pt={0}>
        <Item>
          <SpsDatePicker
            label='Date'
            helperText={smScreenSize ? ' ' : null}
            value={date}
            dsisableOpenPicker={index>0}
            readOnly={index > 0}
            onAccept={setSelectedDate}
          />
        </Item>
      </Grid>
      <Grid item {...ItemWidths.reset} display={{ xs: 'block', md: 'none' }}>
        <Item ><ResetButton /></Item>
      </Grid>
      <Grid item {...ItemWidths.start} sx={{padding: '0'}}>
        <Item>
          <SpsTimePicker
            className="start-time"
            label={lgScreenSize ? 'Start' : 'hh:mm'}
            value={data[index].start}
            helperText=" "
            onChange={(newValue: Dayjs) => {
              setData((prev) => {
                const newData = [...prev]
                newData[index].start = newValue
                calculateTotal(newData[index])
                return newData
              })
            }}
          />
        </Item>
      </Grid>
      <Grid item {...ItemWidths.end}>
        <Item>
          <SpsTimePicker
            className="end-time"
            label={lgScreenSize ? 'End' : 'hh:mm'}
            value={data[index].end}
            helperText={data[index].end?.isBefore(data[index].start) ? `(${date.add(1, 'day').format('dddd')})` : ' ' }
            onChange={(newValue: Dayjs) => {
              setData((prev) => {
                const newData = [...prev]
                newData[index].end = newValue
                calculateTotal(newData[index])
                return newData
              })
            }}
          />
        </Item>
      </Grid>
      <Grid item {...ItemWidths.breaks}>
        <Item>
          <Breaks
            className="breaks"
            label={lgScreenSize ? 'Breaks' : 'hrs:mins'}
            format={lgScreenSize ?  'HHh:mmm' : 'HHhr:mmmin'}
            disabled={!data[index].start || !data[index].end}
            value={data[index].breaks}
            maxTime={calculateDuration(data[index].start, data[index].end)}
            onChange={(newValue: Dayjs) => {
              setData((prev) => {
                const newData = [...prev]
                newData[index].breaks = newValue || dayjs({ hour:0, minute:0 })
                calculateTotal(newData[index])
                return newData
              })
            }}
          />
        </Item>
      </Grid>
      <Grid item {...ItemWidths.total}>
        <Item>
          <Total
            className="total"
            readOnly
            label={lgScreenSize ? 'Total' : 'hrs:mins'}
            format={lgScreenSize ?  'HHh:mmm' : 'HHhr:mmmin'}
            value={data[index].total}
          />
        </Item>
      </Grid>
      <Grid item {...ItemWidths.reset} display={{ xs: 'none', md: 'block', lg: 'none' }} py={2}>
        <Item ><ResetButton /></Item>
      </Grid>
      <Grid item {...ItemWidths.notes}>
        <Item>
          <Notes
            className="notes"
            label={lgScreenSize ? 'Notes' : null}
            value={data[index].notes || ''}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              setData((prev) => {
                const newData = [...prev]
                newData[index].notes = target.value
                return newData
              })
            }}
          />
        </Item>
      </Grid>
      <Grid item {...ItemWidths.reset} display={{ xs: 'none', lg: 'block' }} py={2}>
        <Item ><ResetButton /></Item>
      </Grid>
    </>
  )
}

type Props = {
  data: DailyData[]
  setData: React.Dispatch<React.SetStateAction<DailyData[]>>
  grandTotal: GrandTotal
  setGrandTotal: React.Dispatch<React.SetStateAction<GrandTotal>>
}

const formatGrandTotal = (grandTotal: GrandTotal) => {
  const hours = grandTotal.hours.toString().padStart(2, '0')
  const minutes = grandTotal.minutes.toString().padStart(2, '0')
  return `${hours}hr:${minutes}min`
}

const TimesheetTable: FunctionComponent<Props> = ({ data, setData, grandTotal, setGrandTotal }): JSX.Element => {
  useEffect(() => {
    const total = data.reduce((acc, el) => {
      acc.hours = acc.hours + el.total.hour() + Math.floor((acc.minutes + el.total.minute()) / 60)
      acc.minutes = (acc.minutes + el.total.minute()) % 60
      return acc
    }, { hours:0, minutes:0 })

    setGrandTotal(total)
  }, [data])

  const padding = '16px 8px'
  const margin = '16px 8px'
  const sx={
    padding: '32px 0 14px',
    margin: '16px 0 0',
    borderBottom: '1px solid #000',
  }

  const rowSpacing = { xs: 2, lg: 2 }
  const columnSpacing = { xs: 0.5, xl: 1, xxl: 2 }
  const display = { xs: 'none', md: 'block' }

  return (
    <>
      <Grid container sx={{...sx, padding: '8px 0' }} rowSpacing={rowSpacing} columnSpacing={columnSpacing} columns={columns} justifyContent="space-between" alignItems="center" className="table-headings">
        <Grid item {...ItemWidths.date} display={display}>
          <Item>Date</Item>
        </Grid>
        <Grid item {...ItemWidths.start} display={display}>
          <Item>Start</Item>
        </Grid>
        <Grid item {...ItemWidths.end} display={display}>
          <Item>End</Item>
        </Grid>
        <Grid item {...ItemWidths.breaks} display={display}>
          <Item>Breaks</Item>
        </Grid>
        <Grid item {...ItemWidths.total} display={display}>
          <Item>Total</Item>
        </Grid>
        <Grid item {...ItemWidths.notes} display={{ xs: 'none', lg: 'block' }}>
          <Item>Notes</Item>
        </Grid>
        <Grid item {...ItemWidths.reset}>
        </Grid>
      </Grid>
        {
          data.map((el) => {
            return (
              <Grid key={el.id} id={el.day} container sx={sx} rowSpacing={rowSpacing} columnSpacing={columnSpacing} columns={columns} justifyContent="space-between" alignItems="center">
                <TimesheetRow
                  index={el.id}
                  day={el.day}
                  date={el.date}
                  data={data}
                  setData={setData}
                />
              </Grid>
            )
          })
        }
      <Grid container sx={{...sx, fontWeight: 500 }} rowSpacing={rowSpacing} columnSpacing={columnSpacing} columns={columns} justifyContent="space-between" alignItems="center" className="">
        <Grid item {...{ lg: 4, md: 6, xs: 24 }}>
          <Box display={{ xs: 'none', md: 'block' }} pb={3}>Total</Box>
        </Grid>
        <Grid item {...ItemWidths.start}>
          <Box display={{ xs: 'block', md: 'none' }} pb={3}>Total</Box>
        </Grid>
        <Grid item {...ItemWidths.end}>
        </Grid>
        <Grid item {...{ lg: 3, md: 4, xs: 4 }}>
        </Grid>
        <Grid item {...{ lg: 3, md: 4, xs: 6 }}>
          <Item>
            <GrandTotalField
              readOnly
              value={formatGrandTotal(grandTotal)}
            />
          </Item>
        </Grid>
        <Grid item {...ItemWidths.reset} display={{ xs: 'none', md: 'block', lg: 'none' }}>
        </Grid>
        <Grid item {...ItemWidths.notes}>
        </Grid>
        <Grid item {...ItemWidths.reset}>
        </Grid>
      </Grid>
    </>
  )
}

export default TimesheetTable